@use "../config/"as *;

#main-visual{
  .outer-box{
    position: relative;
    padding: 0;
    &::before{
      content:'';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 350px;
      z-index: 1;
      background-color: #fff4ed;
    }
  }
  .inner-box{
    @include mq(md) {
      max-width: initial;
    }
  }
  figure{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: (655/750) * 100vw;
    height: auto;
    @include mq(md) {
      width: 68%;
      height: 567px;
      
    }
    img{
      width: 100%;
      height: auto;
      border-radius: 0 0 0 (20/750) * 100vw;
      overflow: hidden;
      @include mq(md) {
        border-radius: 0 0 0 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .message{
    position: relative;
    z-index: 3;
    padding-top: (575/750) * 100vw;
    padding-bottom: (50/750) * 100vw;
    @include mq(md) {
      padding-top: 115px;
      padding-bottom: 250px;
      padding-left: 120px;
    }
    .en{
      color: colors(orange);
      @include font-size(24,16);
      @include mq($until:md) {
        margin-bottom: (20/750) * 100vw;
      }
      @include mq(md) {
        margin-bottom: 30px;
      }
    }
    .jp{
      @extend .title-font;
      @include font-size(34,30);
      line-height: 2;
      @include mq(md) {
      }
    }
  }
}