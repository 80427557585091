@use "variables"as *;
@use "mq"as *;

/**
 * Global
 */

/*! purgecss start ignore */
html>body {
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
  letter-spacing: 0.0１em;
  font-style: normal;
  line-height: 1.4;
  color: colors(text);
  font-size: (24 / 750) * 100vw;
  // overflow-x: hidden;
  @include mq(md) {
    font-size: 15px;
  }
}

/*! purgecss end ignore */

a {
  color: colors(text);
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
}
