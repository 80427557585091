@use "../config/" as *;

$drawerWidth : 100%;
$drawerHeight : 100%;
$drawerBgColor : rgba(256,256,256,0);
$layerBgColor : rgba(256,256,256,1);
$drawerDuration : 0.2s;


@include mq($until: md) {
	//メニュー閉じている
	.main-navigation{
		display: block;
	  pointer-events: none;
	  position: fixed;
	  top: 0;
	  left: 0;
		width: 100%;
		max-height: 100vh;
		height: 100%;
	  z-index: 0;
		.PC & {
			// top: 110px;
			// height: calc(100% - 110px);
		}
	  .nav-wrapper{
			display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 40;
			width: $drawerWidth;
			height: $drawerHeight;
			background: $drawerBgColor;
			box-sizing: border-box;
			overflow-y: auto;
	    //アニメーション設定
	    transition: $drawerDuration all $ease-out;
			.transition-none & {
				transition-duration: 0s !important;
			}
		}
		&[data-header-over='false']{
			z-index: 60;
		}
		&[data-slide='right'] .nav-wrapper{
			transform: translate(100vw, 0);
		}
		&[data-slide='left'] .nav-wrapper{
			transform: translate(-100vw, 0);
		}
		&[data-slide='top'] .nav-wrapper{
			transform: translate(0, -100%);
		}
		&[data-slide='bottom'] .nav-wrapper{
			transform: translate(0, 100%);
			top: auto;
			bottom: 0;
		}
		&[data-slide='zoom'] .nav-wrapper{
			transform: translate(-50%, 0) scale(0.8);
			top: 0;
   		left: 50%;
			opacity: 0;
		}
	}

	//メニュー開く
	.nav-open {
		.site-header{
			position: relative;
			z-index: 30;
			height: $drawerHeight;
		}
		.site-branding{
			z-index: 2;
		}
	  .main-navigation{
	    //display: block;
			pointer-events: inherit;
			overflow-y: auto;
			overflow-x: hidden;
	    .nav-wrapper{
	      opacity: 1;
	    }
			&[data-slide='right'] .nav-wrapper{
				transform: translate(calc(100vw - #{$drawerWidth}), 0);
			}
			&[data-slide='left'] .nav-wrapper{
				transform: translate(0, 0);
			}
			&[data-slide='top'] .nav-wrapper{
				transform: translate(0, 0);
			}
			&[data-slide='bottom'] .nav-wrapper{
				transform: translate(0, 0);
			}
			&[data-slide='zoom'] .nav-wrapper{
				transform: translate(-50%, 0) scale(1);
				opacity: 1;
			}
	  }
		.nav-wrapper{

		}
	}
	//下レイヤー
	.menu-layer{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 25;
		background: $layerBgColor;
		opacity: 0;
	}


}//


//トグルボタン
.toggle-btn{
	position: absolute;
	z-index: 70;
	border: none;
	outline: none;
  background: none;
	top: (12/375)*100vw;
	right: (4/375)*100vw;
	width: (44/375)*100vw;
	height: (44/375)*100vw;
	
	@include mq(md) {
		display: none;
	}

	span {
    transition: all 0.3s;
    display: block;
    background: colors(red);
    position: absolute;
    left: 50%;
    top: 50%;
		width: (23/375)*100vw;
		margin-left: (-12/375)*100vw;
    height: (2/375)*100vw;
		@include mq(md) {
			width: 23px;
			margin-left: -12px;
	    height: 2px;
		}
  }

  .top {
		transform: translateY((-8/375)*100vw) scale(1);
		@include mq(md) {
			transform: translateY(-8px) scale(1);
		}
  }

  .middle {
    background: none;

    &::before,
    &::after {
      transition: all 0.3s;
      content: "";
      width: (23/375)*100vw;
      height: (2/375)*100vw;
      background: colors(red);
      display: block;
      transform: rotate(0deg);
      position: absolute;
			@include mq(md) {
				width: 23px;
				height: 2px;
			}
    }
  }

  .bottom {
		transform: translateY((8/375)*100vw) scale(1);
		.PC body:not(.tablet) &{
			transform: translateY(8px) scale(1);
		}
		@include mq(md) {
			transform: translateY(8px) scale(1);
		}
  }

  &.is-open {
    .top {
      transform: translateY(0px) scale(0) !important;
    }

    .bottom {
      transform: translateY(0px) scale(0) !important;
    }

    .middle {
      &:before {
        transform: rotate(45deg) !important;
      }

      &:after {
        transform: rotate(-45deg) !important;
      }
    }
  }
}
