@use "../config/"as *;

.wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: colors(bg);

  @include mq(md) {
    //padding-bottom: 182px;
  }
}

.outer-box {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: colors(bg);

  // overflow-x: hidden;
  @include mq(md) {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    box-sizing: border-box;
  }

  &.bg {
    background-color: colors(bg2);
  }
}

.inner-box {
  width: 100%;
  max-width: width(sp);
  margin-left: auto;
  margin-right: auto;

  @include mq(md) {
    max-width: width(pc);
  }

  &.s-size {
    @include mq(md) {
      max-width: 700px;
    }
  }

  &.m-size {
    @include mq(md) {
      max-width: 1062px;
    }
  }
}
