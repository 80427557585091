@use "../config/"as *;

header.site-header{
  .outer-box{
    position: relative;
    width: 100%;
    height: (143/750) * 100vw;
    background-color: #fff;
    @include mq(md) {
      height: 108px;
      padding: 30px 60px;
    }
  }

  .inner-box {
    @include mq($until: md) {
      max-width: 100%;
    }
    @include mq(md) {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  h1 {
    @include mq($until: md) {
      position: relative;
      z-index: 40;
      background-color: #fff;
      padding: (44/750) * 100vw (30/750) * 100vw;
    }
    .logo {
      display: block;
      width: (315/750) * 100vw;
      height: (51/750) * 100vw;
      @include mq(md) {
        width: 294px;
        height: 48px;
      }
    }
  }

  nav{
    ul{
      text-align: center;
      @include mq($until: md) {
        padding-top: (275/750) * 100vw;
      }
      @include mq(md) {
        text-align: right;
      }
      li{
        display: block;
        @include mq(md) {
          display: inline-block;
          margin-left: 58px;
        }
        &:first-child{
          margin-left: 0;
        }
        a{
          @include font-size(36,18);
          font-weight: bold;
          @include mq($until: md) {
            display: block;
            padding: (45/750) * 100vw 0;
            letter-spacing: (100em/1000);
          }
        }
        &.tel{
          margin-top: (45/750) * 100vw;
          @include mq(md) {
            display: none;
          }
          a{
            color: colors(red);
            @extend .en;
            font-size: (48/750) * 100vw;
            font-weight: 500;
            letter-spacing: (50em/1000);
            &::before{
              content:'';
              display: inline-block;
              width: (44/750) * 100vw;
              height: (44/750) * 100vw;
              margin-right: (15/750) * 100vw;
              background: url(#{$imgDir}icon_tel.svg) no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}