@use "../config/" as *;

footer.site-footer{
  .outer-box{
    padding: (55/750) * 100vw (60/750) * 100vw;
    background-color: colors(text);
    color: #fff;
    
    @include mq(md) {
      padding: 65px 60px;
    }
  }
  .inner-box {
    @include mq(md) {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .link{
    @include font-size(24,14);
    a{
      color: #fff;
    }
  }
  .copyright{
    @include font-size(20,14);
    @include mq($until:md) {
      margin-bottom: (15/750) * 100vw;
    }
  }
  
}