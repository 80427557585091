@use "../config/"as *;

//icon link
.link-box{
  text-align: right;
}
.text-link-blank{
  display: inline-block;
  color: colors(red);
  border-bottom: 2px solid colors(red);
  @include font-size(32,18);
  font-weight: bold;
  padding-bottom: (6/750) * 100vw;
  @include mq(md) {
    padding-bottom: 4px;
  }
  &::after{
    content:'';
    display: inline-block;
    width: (33/750) * 100vw;
    height: (33/750) * 100vw;
    margin-left: (10/750) * 100vw;
    background: url(#{$imgDir}icon_blank.svg) no-repeat center;
    background-size: contain;
    @include mq(md) {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
  }
}
.text-link-map{
  display: inline-block;
  color: colors(red);
  border-bottom: 2px solid colors(red);
  @include font-size(32,18);
  font-weight: bold;
  padding-bottom: (6/750) * 100vw;
  @include mq(md) {
    padding-bottom: 4px;
  }
  &::after{
    content:'';
    display: inline-block;
    width: (28/750) * 100vw;
    height: (35/750) * 100vw;
    margin-left: (10/750) * 100vw;
    background: url(#{$imgDir}icon_map.svg) no-repeat center;
    background-size: contain;
    transform: translateY(0.25em);
    @include mq(md) {
      width: 18px;
      height: 22px;
      margin-left: 5px;
    }
  }
}

//letterspacing
.ls-20 {
  letter-spacing: (-200em/1000);
}

.ls-50 {
  letter-spacing: (-500em/1000);
}

.ls-100 {
  letter-spacing: (-1000em/1000);
}

.caps {
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}

.forSpBlock {
  display: block;

  @include mq(md) {
    display: none;
  }
}

.forPcBlock {
  display: none;

  @include mq(md) {
    display: block;
  }
}