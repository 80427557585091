@use "../config/"as *;

#contact {
  .outer-box {
    position: relative;
    z-index: 0;
    padding-top: (120/750) * 100vw;
    padding-bottom: (180/750) * 100vw;
    @include mq(md) {
      padding-top: 120px;
      padding-bottom: 125px;
    }
  }

  .inner-box {
    position: relative;
  }

  /*form reset --- */
  input,
  button,
  select,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
  }

  input[type='submit'],
  input[type='button'],
  label,
  button,
  select {
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }

  /* --- form reset*/

  input[type="checkbox"] {
    position: relative;
    background-color: #fff;
    border: 1px solid #dedede;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin: 0 7px 3px 0;
    vertical-align: middle;
    color: #000;
    padding: 0;

    &:checked {
      &::after {
        content: "\02714";
        display: block;
        position: absolute;
        width: 1em;
        height: 1em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -58%);
        color: colors(primary);
        font-size: 16px;
        line-height: 1;
        text-align: center;
      }
    }
  }

  input,
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    width: 100%;
    border: 1px solid #dedede;
    background-color: #eeeeee;
    border-radius: (4/750) * 100vw;
    @include font-size(28, 14);
    font-weight: bold;
    padding: (20/750) * 100vw (20/750) * 100vw;
    color: colors(text);

    @include mq(md) {
      border-radius: 4px;
      padding: 10px 15px;
    }

    &::-ms-input-placeholder {
      //color: #ced0d5;
    }

    &::placeholder {
      //color: #ced0d5;
    }
  }

  select:invalid {
    //color: #ced0d5;
  }

  input[type="radio"] {
    position: relative;
    background-color: #fff;
    border: 1px solid #dedede;
    width: (44/750) * 100vw;
    height: (44/750) * 100vw;
    margin: 0 7px 3px 0;
    border-radius: 50%;
    vertical-align: middle;
    color: #000;
    padding: 0;

    @include mq(md) {
      width: 29px;
      height: 29px;
    }

    &:checked {
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: (28/750) * 100vw;
        height: (28/750) * 100vw;
        top: (6/750) * 100vw;
        left: (6/750) * 100vw;
        border-radius: 50%;
        background-color: colors(primary);

        @include mq(md) {
          top: 5px;
          left: 5px;
          width: 17px;
          height: 17px;
          background-color: #2868a3;
        }
      }
    }
  }

  .select-wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: calc(50% - (11/750) * 100vw);
      right: (22/750) * 100vw;
      width: (26/750) * 100vw;
      height: (22/750) * 100vw;
      z-index: 2;
      background: url(#{$imgDir}icon_select.png) no-repeat;
      background-size: contain;
      pointer-events: none;

      @include mq(md) {
        top: calc(50% - 6px);
        right: 15px;
        width: 15px;
        height: 13px;
      }
    }
  }

  .agree-check {
    margin-bottom: (50/750) * 100vw;
    @include font-size(24, 14);
    font-weight: bold;
    text-align: center;

    @include mq(md) {
      padding: 0;
      margin-bottom: 35px;
    }

    a {
      color: colors(primary);
      text-decoration: underline;
    }
  }

  .btn-submit,
  .btn-back {
    text-align: center;
    width: 100%;
    max-width: 540px;
    border: none;
    border-radius: (60/750) * 100vw;
    padding: (34/750) * 100vw;
    background-color: colors(primary);
    @include font-size(36, 20);
    font-weight: bold;
    color: #FFF;

    @include mq(md) {
      border-radius: 36px;
      padding: 20px;
      margin: 0 auto;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  .btn-back {
    background-color: #737a92;
  }

  .btn-box {
    display: flex;
    justify-content: space-between;

    form {
      width: 48%;
    }
  }

  .error {
    position: relative;
    @include font-size(24, 14);
    margin-top: (16/750) * 100vw;
    color: #737a92;
    font-weight: bold;

    @include mq(md) {
      margin-top: 5px;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: (-70/750) * 100vw;
      right: (15/750) * 100vw;
      width: (26/750) * 100vw;
      height: (26/750) * 100vw;
      z-index: 2;
      background: url(#{$imgDir}icon_error.svg) no-repeat;
      background-size: contain;
      pointer-events: none;

      @include mq(md) {
        top: -33px;
        right: 15px;
        width: 15px;
        height: 15px;
      }
    }

    &.gr{
      text-align: center;
      margin-bottom: 1em;
      &::after {
        display: none;
      }
    }
  }

}



.dl-form {
  width: 100%;
  padding-top: (20/750) * 100vw;
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    max-width: 795px;
    margin: 0 auto;
    padding-top: 20px;
  }

  dt {
    @include font-size(30, 14);
    font-weight: bold;
    color: colors(em);
    margin-bottom: (15/750) * 100vw;

    @include mq(md) {
      width: 27%;
      padding: 12px 0;
      margin-bottom: 30px;
    }
  }

  dd {
    margin-bottom: (50/750) * 100vw;

    @include mq(md) {
      width: 73%;
      margin-bottom: 30px;
    }

    &:last-child {
      @include mq($until: md) {
        margin-bottom: (25/750) * 100vw;
      }
    }

    label {
      display: block;
      cursor: pointer;
      @include font-size(28, 16);
      font-weight: bold;
    }

    label+label {
      margin-top: (10/750) * 100vw;

      @include mq(md) {
        margin-top: 10px;
      }
    }
  }

  &.confirm {
    dd {
      @include font-size(30, 14);

      @include mq(md) {
        padding: 12px 0;
      }
    }
  }
}

p+.dl-form {
  margin-top: 1em;
}

iframe#mailform {
  width: 100%;
  overflow: hidden;
}

.g-recaptcha,
#recaptcha {
  width: 302px;
  margin: 0 auto (40/750) * 100vw;

  @include mq(md) {
    margin-bottom: 35px;
  }
}