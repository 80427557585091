@use "variables"as *;
@use "mq"as *;
.title-font{
  font-family: fot-tsukuardgothic-std, sans-serif;
}
.underline{
  display: inline-block;
  position: relative;
  background:linear-gradient(transparent 0%, transparent 60%, colors(yellow) 61%, colors(yellow) 79%, transparent 80%,transparent 100%);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.en{
  font-family:'Poppins', sans-serif;
  text-transform:uppercase;
  letter-spacing: (200em/1000);
}