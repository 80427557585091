@use "../config/" as *;

#company{
  .outer-box{
    padding-top: (80/750) * 100vw;
    padding-bottom: (130/750) * 100vw;
    background: none;
    background-color: #fff2ea;
    @include mq(md) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
  .inner-box{
    @include mq(md) {
    }
  }

  .company-outline{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid colors(text);
    dt,dd{
      @include font-size(24, 16);
      border-bottom: 1px solid colors(text);
      padding: (22/750) * 100vw (13/750) * 100vw;
      @include mq(md) {
        padding: 20px 20px 18px 22px;
      }
    }
    dt{
      width: 27%;
      background-color: #fff;
      font-weight: bold;
    }
    dd{
      width: 73%;
      background-color: #fff7f2;
    }
  }

  .map{
    margin-bottom: (20/750) * 100vw;
    @include mq(md) {
      width: 100%;
      margin-bottom: 20px;
    }
    iframe{
      width: 100%;
      height: (540/750) * 100vw;
      @include mq(md) {
        height: 425px;
      }
    }
  }
}
