@use "mq"as *;
@use "variables"as *;

//フォントサイズ
// @mixin font-size($sizeSP, $sizePC, $sizeTAB: #{$sizePC}) {
//   font-size: #{$sizePC}px;

//   @include mq($until: lg) {
//     font-size: #{$sizeTAB}px;
//   }

//   @include mq($until: md) {
//     font-size: ($sizeSP/750) * 100vw;
//   }
// }

@mixin font-size($sizeSP, $sizePC, $sizeTAB: #{$sizePC}) {
  font-size: ($sizeSP/750) * 100vw;

  @include mq(md) {
    font-size: #{$sizePC}px;
  }
}

@mixin mincho() {
  font-family: YakuHanMP,
    'A1 明朝',
    'Times New Roman',
    'YuMincho',
    'Hiragino Mincho ProN',
    'Yu Mincho', 'MS PMincho', serif;
}

.mincho {
  @include mincho;
}

//スクロールでフェードイン・アウトするパーツ（左上ロゴ、右下のナビ）
@mixin transition-fadeout() {
  transition: opacity 1.2s $ease-out;
  opacity: 1;

  &.fadeOut {
    opacity: 0;
    pointer-events: none;

    a {
      pointer-events: none;
    }
  }
}

@mixin transition-fadeoutY() {
  transition: transform 1.2s $ease-out, opacity 1.2s $ease-out;
  opacity: 1;

  &.fadeOut {
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none;

    a {
      pointer-events: none;
    }
  }
}

//背景画像
@mixin bg-img($filename) {
  background-image: url(#{$imgDir}#{$filename}_sp.jpg);

  .Tablet & {
    background-image: url(#{$imgDir}#{$filename}_sp@2x.jpg);
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: map-get($mq-breakpoints, md)),
  only screen and (min-resolution: 192dpi) and (max-width: map-get($mq-breakpoints, md)) {
    background-image: url(#{$imgDir}#{$filename}_sp@2x.jpg);
  }

  @include mq(md) {
    background-image: url(#{$imgDir}#{$filename}.jpg);

    .Tablet & {
      background-image: url(#{$imgDir}#{$filename}@2x.jpg);
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: map-get($mq-breakpoints, md)),
  only screen and (min-resolution: 192dpi) and (min-width: map-get($mq-breakpoints, md)) {
    background-image: url(#{$imgDir}#{$filename}@2x.jpg);
  }

  background-repeat: no-repeat;
  background-size: cover;
}

//CSS内でアイコン
@mixin icon($code) {
  display: inline-block;
  content: '#{$code}';
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  vertical-align: text-bottom;
}

//clearfix
@mixin clearfix() {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

//センター配置
@mixin posCenter() {
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//object-fit
@mixin object-fit($type: cover, $pos-x: 50%, $pos-y: 50%) {
  object-fit: $type;
  object-position: $pos-x $pos-y;
  font-family: #{'"object-fit:' $type '; object-position:' $pos-x $pos-y '"'};
}

//背景画像
@mixin bg-img($filename) {
  background-image: url(#{$imgDir}#{$filename}_sp.jpg);

  // .Tablet & {
  //   background-image: url(#{$imgDir}#{$filename}_sp@2x.jpg);
  // }
  // @media only screen and (-webkit-min-device-pixel-ratio: 2) and #{$breakpoint-SP}, only screen and (min-resolution: 192dpi) and #{$breakpoint-SP} {
  //   background-image: url(#{$imgDir}#{$filename}_sp@2x.jpg);
  // }
  @include mq(md) {
    background-image: url(#{$imgDir}#{$filename}.jpg);

    .Tablet & {
      background-image: url(#{$imgDir}#{$filename}@2x.jpg);
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: map-get($mq-breakpoints, md)),
  only screen and (min-resolution: 192dpi) and (min-width: map-get($mq-breakpoints, md)) {
    background-image: url(#{$imgDir}#{$filename}@2x.jpg);
  }

  background-repeat: no-repeat;
  background-size: cover;
}

//hoverの画像scale演出
@mixin img-scale() {
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  //height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%) scale(1);
}

@mixin img-scale-hover() {
  transform: translate(-50%, -50%) scale(1.1);
}

// 縦書き
@mixin tategaki($value: 100em) {
  letter-spacing: ($value/1000);
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  -webkit-font-feature-settings: 'pkna';
  font-feature-settings: 'pkna';
}
