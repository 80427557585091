@use "../config/"as *;

#services {
  .outer-box {
    padding-top   : (110/750) * 100vw;
    padding-bottom: (80/750) * 100vw;

    @include mq(md) {
      padding-top   : 110px;
      padding-bottom: 120px;
    }
  }

  .inner-box {
    position: relative;

    @include mq(md) {}
  }

}

h2 {
  padding-bottom: (25/750) * 100vw;
  margin-bottom : (60/750) * 100vw;
  border-bottom : 1px solid #bac0d0;

  @include mq(md) {
    padding-bottom: 30px;
    margin-bottom : 60px;
  }

  .en {
    color: colors(orange);
    @include font-size(24, 16);
    font-weight: normal;

    @include mq($until:md) {
      display      : block;
      margin-bottom: (20/750) * 100vw;
    }

    @include mq(md) {
      display     : inline-block;
      margin-right: 20px;
    }
  }

  .jp {
    @extend .title-font;
    @include font-size(38, 30);

    @include mq(md) {
      display: inline-block;
    }
  }

  &.border-none {
    padding-bottom: 0;
    border-bottom : none;
    margin-bottom : (30/750) * 100vw;

    @include mq(md) {
      margin-bottom: 50px;
    }
  }
}

.flex-box {
  margin-bottom: (130/750) * 100vw;

  @include mq(md) {
    display      : flex;
    margin-bottom: 70px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .img-box {
    @include mq($until:md) {
      margin-bottom: (35/750) * 100vw;
    }

    @include mq(md) {
      width       : 33%;
      margin-right: 40px;
    }

    img {
      width : 100%;
      height: auto;
    }
  }

  .text-box {
    @include mq(md) {
      width: calc(66% - 40px);
    }

    h3 {
      @include font-size(34, 24);
      font-weight  : bold;
      margin-bottom: (25/750) * 100vw;

      @include mq(md) {
        margin-bottom: 15px;
      }

      span {
        display: block;
        @include font-size(24, 15);
        font-weight  : normal;
        margin-bottom: (20/750) * 100vw;

        @include mq(md) {
          margin-bottom: 10px;
        }
      }
    }

    p {
      line-height  : 2;
      margin-bottom: 1.5em;

      .note {
        @include font-size(22, 14);
      }
    }

  }

  .outline-box {
    @include mq($until:md) {
      margin-bottom: (95/750) * 100vw;
    }

    @include mq(md) {
      width       : calc(50% - 20px);
      margin-right: 40px;
    }
  }

  .map-box {
    @include mq(md) {
      width: calc(50% - 20px);
    }
  }

  h4 {
    padding-bottom: 5px;
    margin-bottom : 30px;
    margin-top: 40px;
    border-bottom : 1px solid #bac0d0;
    font-size     : 1.4em;
    letter-spacing: .05em;
  }

  .youtube {
    margin-bottom: 40px;
    width        : 100%;
    aspect-ratio : 16 / 9;

    iframe {
      width : 100%;
      height: 100%;
    }
  }
}